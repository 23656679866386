/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Nav from "./navigation"
import { Helmet } from "react-helmet"

import "sanitize.css"
import GlobalStyle from "../theme/globalStyle"
import styled, { ThemeProvider } from "styled-components"
import bgImg from "../images/bg-01-01-01-01.png"

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Title</title>
        <link href="https://fonts.googleapis.com/css2?family=Allura&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:wght@400;600&display=swap" rel="stylesheet" />

      </Helmet>
      <GlobalStyle />
      <LayoutGrid>
        <Nav />
        <Header />
        <Main>{children}</Main>
      </LayoutGrid>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const LayoutGrid = styled.div`
  background-image: url(${bgImg});
  min-height: 100vh;
  height: 100%;
  align-content: start;
  /* overflow: hidden; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  @media (min-width: 768px) {
    grid-gap: 1rem;
  }
  @media (min-width: 1024px) {
    grid-gap: 1rem;
  }

  @media (min-width: 1024px) {
  display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 2rem;
    /* grid-row-gap: 1rem; */

    grid-template-areas:
      "nav header"
      "nav main";
  }
`

const Main = styled.main`
  /* grid-area: main; */
`
