import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import logo from '../images/mm-logo-wht.svg'

import { FiFacebook, FiInstagram, FiTwitter, FiYoutube } from "react-icons/fi"

const Nav = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <SideNav>
      <img
        src={logo}
        alt=""
      />

      <Menu>
        <a href="https://www.archangelbook.com/#about">About</a>
        <a href="https://www.archangelbook.com/">Book</a>
        <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=HSH4QXLBYH5NQ&source=url">
          Donate
        </a>
      </Menu>
      <SocialIcons>
        <a href="https://www.facebook.com/JerryCalvinMcKinney">
          <FiFacebook />
        </a>
        {/**<a href="https://www.instagram.com/">
          <FiInstagram />
  </?a>*/}
        <a href="https://twitter.com/DrJCMcKinney">
          <FiTwitter />
        </a>
        <a href="https://www.youtube.com/channel/UCuJdsc2qou7ytkPV5osd6pg/videos">
          <FiYoutube />
        </a>
      </SocialIcons>
    </SideNav>
  )
}

export default Nav

const SideNav = styled.section`
  align-self: start;
  display: grid;
  justify-items: center;
  background-color: var(--blue);
  grid-gap: 1rem;
  align-content: start;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  img {
    width: 25%;
    height: auto;
    align-self: center;
  }

  padding: 1rem;
  font-size: 1rem;

  footer {
    display: none;
    color: var(--white);
    text-align: center;
    line-height: 1;
    padding: 2rem 0 1rem;
  }

  @media (min-width: 768px) {
    grid-gap: 0;
    grid-template-rows: auto auto auto;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;

    img {
      width: 15%;
      grid-column: 1 / -1;
    }
  }

  @media (min-width: 1024px) {
    grid-area: nav;
    justify-items: center;
    /* grid-template-rows: auto 1fr auto; */
    grid-template-rows: auto 1fr 200px;
    height: 100%;
    grid-template-columns: 1fr;
    align-items: start;
    align-content: start;

    img {
      width: 60%;
      padding-bottom: 1rem;
    }

    footer {
      display: block;
    }
  }
`

const Menu = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;

  a {
    color: white;
  }

  @media (min-width: 1024px) {
    grid-auto-flow: row;
    /* align-content: space-around; */
    justify-items: center;
  }
`

const SocialIcons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  a {
    color: orange;
  }

  @media (min-width: 1024px) {
    grid-auto-flow: row;
    /* align-content: space-around; */
    justify-items: center;
    font-size: 1rem;
    align-content: end;
  }
`

SideNav.propTypes = {
  siteTitle: PropTypes.string,
}

SideNav.defaultProps = {
  siteTitle: ``,
}
