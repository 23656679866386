import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:700|Roboto:300,400,700&display=swap');

:root {
    --blue: #26375f;
    --orange: #f07f49;
    --black: #1c2845;
    --white: #fefffe;
    --ightGrey: #eaeaea;
    --grey: #bfbdc1;
    --darkGrey: #57606f;

    // Breakpoints
    --mobileS: '320px',
    --mobileM: '375px',
    --mobileL: '425px',
    --tablet: '768px',
    --laptop: '1024px',
    --laptopL: '1440px',
    --desktop: '2560px'
  }
   
    body {
        color: var(--black);
        font-family: 'Montserrat', sans-serif;
    }

    a {
        text-decoration: none;
        
        cursor: pointer;
    }

    h1, h2, h3, h4, h5, h6 {
        
        margin: 0 0 .5rem;
        color: var(--black);
    }

    hr {
        
    }

    p {
        margin: 0 0 1.5rem;
    }
`

export default GlobalStyle
