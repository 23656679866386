
import React from "react"

import styled from 'styled-components'

const Header = () => (
  <MainHeader>
    <div>Every home a church... every heart a worship center.</div>
  </MainHeader>
)

const MainHeader = styled.header`
  padding:  1rem;
  font-weight: 800;
  font-size: 2.35rem; 
  line-height: 1;
  font-family: 'Allura', cursive;
  grid-area: header;

 @media (min-width: 768px) {
  padding: 1rem 2rem 0;
 }
 @media (min-width: 1024px) {
  padding: 2rem 0 1rem 0;
  font-size: 4rem;
 }
`



export default Header
